.cms-page-view {
    .page-title {
        padding: 50px 0 13px;
        text-align: center;
        font-size: 36px;
    }

    .col-main {
        margin-bottom: 30px;

        .media-min(@screen-xxl, {
            max-width: 900px;
            margin-bottom: 67px;
        });
    }
}

.std {
    color: @color-main;
    font-size: @font-xs;

    a:not(.main-btn) {
        display: inline-block;
        padding: 1px 4px 1px;
        color: @color-main;
        
        &:hover {
            text-decoration: none;
        }
    }

    ul {
        li {
            position: relative;
            padding-left: 25px;

            &::before {
                content: '';
                position: absolute;
                top: 5px;
                left: 2px;
                width: 9px;
                height: 9px;
                background: @color-orange;
                border-radius: 50%;
            }
        }

        &.border-left {
            li {
                margin-bottom: 25px;
                padding-left: 18px;

                &::before {
                    top: 0;
                    left: 0;
                    width: 3px;
                    height: 100%;
                    border-radius: 0;
                }
            }
        }

        &.no-bullets {
            li {
                padding-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }

    ol {
        counter-reset: demical;

        li {
            counter-increment: demical;

            &::before {
                content: counter(demical) ". ";
                margin-right: 2px;
                font-weight: @font-weight-bold;
            }
        }
    }

    ul,
    ol,
    dl,
    p,
    address,
    blockquote {
        margin: 0 0 18px;
    }

    blockquote {
        position: relative;
        padding: 35px 25px;
        font-size: @font-l;
        line-height: 1.6;
        color: #616161;
        font-weight: @font-weight-bold;

        .media-min(@screen-m, {
            padding: 35px;
        });

        &::before {
            content: '\0022';
            position: absolute;
            top: 0;
            left: 4px;
            transform: rotate(180deg);
            font-size: 40px;
            line-height: 1;
        }

        &::after {
            content: '\0022';
            position: absolute;
            right: 4px;
            bottom: 0;
            font-size: 40px;
            line-height: 1;
        }
    }

    li {
        margin-bottom: 10px;
        line-height: 18px;

        p {
            margin: 0;

            img {
                margin-top: 10px;
            }
        }
    }

    dl {
        dt {
            margin-bottom: 8px;
            color: #000;
            text-transform: uppercase;
        }

        dd {
            margin: 0 0 18px;
            padding: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 10px -1px;
    }

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6 {
        color: @color-main;
        font-weight: @font-weight-bold;
        text-transform: uppercase;
        word-break: break-word;
    }

    h1, .h1 {
        font-size: 28px;
    }

    h2, .h2 {
        font-size: @font-l;
    }

    h3, .h3 {
        font-size: @font-m;
    }

    h4, .h4 {
        font-size: @font-xs;
    }

    h5, .h5 {
        font-size: @font-main;
    }

    h6, .h6 {
        font-size: @font-xxs;
    }

    .data-table {
        thead {
            th {
                color: @color-main;
            }
        }

        tbody {
            th,
            td {
                color: @color-main;
                padding: 13px 19px;
                font-size: @font-main;
                text-transform: uppercase;
            }
        }
    }

    .title-wrap {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 100%;

        .title {
            position: relative;
            margin: 25px 0;
            padding-left: 16px;
            border-left: 7px solid @color-orange;
            font-size: 32px;
            line-height: 1.3;
            font-weight: @font-weight-bold;
        }
    }

    .border-wrap {
        padding: 23px;
        margin-top: 15px;
        border: 3px solid @color-orange;
        font-weight: @font-weight-bold;

        .media-min(@screen-m, {
            padding: 30px 43px;
        });
    }

    .row-items-3 {
        .media-min(@screen-m, {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        });

        .item {
            text-align: center;

            .media-min(@screen-m, {
                width: 33.333%;
                padding: 0 34px;
            });

            h4 {
                font-size: 30px;
            }

            p {
                margin: 0;
                line-height: 2;
            }
        }
    }

    .table-wrapper-scroll {
        overflow: hidden;
        overflow-x: auto;
        border: 1px solid @color-border;

        table {
            width: 100%;

            th,
            td {
                border: 1px solid @color-border;
                font-weight: @font-weight-bold;
                vertical-align: middle;

                .media-max(@screen-m, {
                    font-size: @font-xxs;
                });
            }

            thead {
                th {
                    padding: 10px;
                    text-align: center;
                    background: #F8F8F8;
                    border-top: none;

                    &:first-child {
                        border-left: none;
                    }

                    &:last-child {
                        min-width: 200px;
                        border-right: none;
                    }
                }
            }

            tbody {
                tr {
                    &:nth-child(2n) {
                        td {
                            background: #F8F8F8;
                        }
                    }

                    &:last-child {
                        td {
                            border-bottom: none;
                        }
                    }
                }

                td {
                    padding: 10px;
                    background: @color-white;

                    &:not(:last-child) {
                        text-align: center;
                    }

                    &:first-child {
                        border-left: none;
                    }

                    &:last-child {
                        min-width: 200px;
                        border-right: none;
                    }
                }
            }
        }
    }
}


// Substances page

.cms-substances {
    .col-main {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        .media-min(@screen-l, {
            max-width: 1185px;
            margin: 0 auto 40px;
        })
    }

    .page-title {
        margin-bottom: 10px;
        border-bottom: 1px solid @color-border;
        text-align: left;
    }

    .substance-contents {
        display: flex;
        flex-wrap: wrap;

        .substance-wrapper {
            width: 100%;
            padding-right: 10px;

            .media-min(@screen-m, {
                width: 33.333%;
            });

            .media-min(@screen-xl, {
                width: 25%;
            });

            .substance-item {
                .link;
                margin: 3px 0;
                line-height: 1.8;
                font-weight: @font-weight-semibold;
            }
        }
    }
}

// Substances page

.cms-brands {
  .col-main {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 40px;

    .media-min(@screen-l, {
      max-width: 1185px;
      margin: 0 auto 40px;
    })
  }

  .page-title {
    margin-bottom: 10px;
    border-bottom: 1px solid @color-border;
    text-align: left;
  }

  .brand-contents {
    display: flex;
    flex-wrap: wrap;

    .brand-wrapper {
      width: 100%;
      padding-right: 10px;

      .media-min(@screen-m, {
        width: 33.333%;
      });

      .media-min(@screen-xl, {
        width: 25%;
      });

      .brad-item {
        .link;
        margin: 3px 0;
        line-height: 1.8;
        font-weight: @font-weight-semibold;
      }
    }
  }
}

// FAQ page

.cms-steroids-faq {
    .col-main {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        .media-min(@screen-l, {
            max-width: 1185px;
            margin: 0 auto 40px;
        })
    }

    .page-title {
        margin-bottom: 20px;
        border-bottom: 1px solid @color-border;
        text-align: left;
    }

    .faq {
        .question-block {
            margin-bottom: 20px;
            border-radius: 8px;
            overflow: hidden;

            &.visible {
                .question {
                    &:before {
                        transform: translateY(-50%) rotate(-270deg);
                    }
                }
            }

            .question {
                position: relative;
                display: block;
                padding: 10px 40px 10px 20px;
                background: @color-main;
                color: @color-white;
                font-size: @font-m;
                font-weight: @font-weight-regular;
                cursor: pointer;
                border: 1px solid @color-main;
                border-radius: 8px 8px 0 0;

                &::before {
                    .icon;
                    .icon-arrow-right;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    transform: translateY(-50%) rotate(-90deg);
                    transition: transform .3s ease-in-out;
                    font-size: 16px;
                }

                .media-min(@screen-m, {
                    font-size: @font-xl;
                });

                p {
                    margin: 0;
                }
            }

            .answer {
                padding: 20px 20px 0;
                display: none;
                border: 1px solid @color-border;
                border-top: none;
                border-radius: 0 0 8px 8px;
            }
        }
    }
}

// References page

.cms-references {
    .col-main {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        .media-min(@screen-l, {
            max-width: 1185px;
            margin: 0 auto 40px;
        })
    }

    .page-title {
        margin-bottom: 20px;
        border-bottom: 1px solid @color-border;
        text-align: left;
    }

    .std {
        .references {
            .media-min(@screen-m, {
                display: flex;
                flex-wrap: wrap;
                margin: 0 -10px;

                > div {
                    padding: 0 10px;
                    width: 50%;
                }
            });

            .ref-title {
                margin-bottom: 20px;
                font-size: @font-xxl;
                font-weight: @font-weight-bold;
            }

            .approved-supplier {
                .ref-content {
                    img,
                    .item {
                        margin-left: 0;
                    }
                }
            }

            .ref-content {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                img,
                .item {
                    margin: 0 auto;
                    margin-bottom: 20px;
                }

                .item {
                    width: 100%;

                    .media-min(@screen-m, {
                        width: 50%;
                    });

                    img {
                        display: block;
                        margin: auto;
                    }
                }
            }
        }
    }
}

// How to order page

.cms-how-to-order {
    .col-main {
        padding-left: 20px;
        padding-right: 20px;
        margin-bottom: 40px;

        .media-min(@screen-l, {
            max-width: 1185px;
            margin: 0 auto 40px;
        });
    }

    .page-title {
        margin-bottom: 20px;
        border-bottom: 1px solid @color-border;
        text-align: left;
    }
}

.how-to-order {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .how-text {
        width: 100%;

        .media-min(@screen-m, {
            width: 48%;
        });

        a {
            color: #FC5500;
            text-decoration: none;

            &:hover {
                color: @color-black;
            }
        }

        .title {
            margin-bottom: 10px;
            font-size: @font-xxl;
            font-weight: @font-weight-bold;
        }

        .content {
            font-size: @font-m;

            .register {
                padding-bottom: 85px;
            }
        }
    }

    .how-img {
        width: 50%;

        .media-max(@screen-m, {
            display: none;
        })
    }
}

// Contact Us

.contacts-index-index {
    .col-main {
        padding-left: 20px;
        padding-right: 20px;
        max-width: 620px;
        margin: 0 auto 40px;
    }

    .page-title {
        padding: 50px 0 13px;
        margin-bottom: 20px;
        border-bottom: 1px solid @color-border;
        text-align: left;
        font-size: 36px;
    }

    .contact-wrap {
        width: 100%;
        margin: 26px 0;
        padding: 0 26px 30px;
        border-top: 7px solid @color-orange;
        border-radius: 8px;
        background: #f3f3f3;
        overflow: hidden;

        .legend {
            position: relative;
            padding: 17px 0 14px;
            font-size: @font-l;
            font-weight: @font-weight-bold;

            .media-min(@screen-m, {
                font-size: @font-xxl;
            });

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                width: 200%;
                height: 1px;
                transform: translateX(-50%);
                background: @color-border;
            }
        }

        .form-list {
            margin-top: 20px;
        }

        .buttons-set {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;

            .required {
                width: 100%;
                margin-bottom: 15px;
                font-size: @font-xxxs;
                text-align: left;
            }

            .back-link {
                margin: 0;
            }

            .main-btn {
                display: block;
                min-width: 136px;
            }
        }
    }
}

// Visa Direct Instructions

.visa-direct-instructions {
    max-width: 656px;
    margin: 54px auto;

    .instruction-block {
        border: 1px solid #E9E9E9;
        border-radius: 10px;
        overflow: hidden;

        &:not(:last-child) {
            margin-bottom: 54px;
        }

        &.opened {
            .instruction-title {
                &:before {
                    transform: translateY(-50%) rotate(-270deg);
                }
            }
        }

        .instruction-title {
            position: relative;
            padding: 20px 60px 19px 24px;
            background: #E9E9E9;
            border-radius: 10px;
            font-size: @font-xs;
            font-weight: @font-weight-bold;
            line-height: 1.2;
            cursor: pointer;

            .media-min(@screen-m, {
                font-size: @font-m;
            });

            &::before {
                .icon;
                .icon-arrow-right;
                position: absolute;
                top: 50%;
                right: 25px;
                transform: translateY(-50%) rotate(-90deg);
                transition: transform .3s ease-in-out;
                font-size: 16px;
            }

            p {
                margin: 0;
            }
        }

        .instruction-content {
            padding: 28px 24px 0;
            font-size: @font-xs;
            line-height: 1.2;

            .media-min(@screen-m, {
                font-size: @font-m;
            });

            p {
                margin-bottom: 20px;

                img {
                    margin-bottom: 0;
                }
            }

            img {
                display: block;
                margin: 0 auto 20px;
            }
        }
    }
}