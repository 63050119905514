// General

html,
body {
    height: 100%;
    color: @color-main;
}

html {
    font-size: @font-main;
}

body {
    min-width: 320px;
    font: @font-weight-regular @font-main @main-font;
}

.wrapper {
    min-height: 100%;
    margin: 0 auto;
    background: @color-white;

    > .page {
        display: flex;
        flex-direction: column;
        min-height: 100vh;

        .footer-container {
            margin-top: auto;
        }
    }
}

.loading-mask {
    bottom: 0;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
    background: rgba(255,255,255,.5);

    .loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        background: url('../images/loader.gif') center no-repeat;
        z-index: 100;

        > img {
            display: none;
        }
    }
}

.col-main {
    padding: 0 8px;

    .media-min(@screen-xxl, {
        max-width: 1500px;
        margin: 0 auto;
        padding: 0 10px;
    });
}

.wrapper:after {
    .clearfix;
}

#fancybox-overlay,
#fancybox-wrap {
    display: none !important;
}

// Breadcrumbs

.breadcrumbs {
    padding: 16px 20px 10px;
    max-width: 1520px;
    margin: 0 auto;

    li {
        display: inline-block;

        a,
        strong {
            padding: 2px 3px;
            color: @color-black;
            font-weight: @font-weight-regular;
        }

        a {
            .link;
            color: @color-black;
        }
    }
}

// Price box

.price-box {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    justify-content: flex-end;

    .price {
        font-size: 28px;
        font-weight: @font-weight-bold;
        line-height: 1;

        .media-max(@screen-m, {
            font-size: @font-l;
        });
    }

    .old-price {
        .price-label {
            display: none;
        }

        .price {
            color: #959595;
            font-size: @font-m;

            .media-max(@screen-m, {
                font-size: @font-main;
            });
        }
    }

    .special-price {
        margin-right: 9px;

        .price-label {
            display: none;
        }

        .price {
            color: @color-red;
        }
    }
}

// Advice block

.block.advice-block {
    padding: 21px;
    margin-bottom: 15px;
    border-top: 7px solid @color-orange;
    border-radius: 8px;
    overflow: hidden;
    background: #f8f8f8;

    .block-title {
        padding-bottom: 35px;
        font-size: @font-xxl;
        font-weight: @font-weight-bold;
        line-height: 32px;
        text-transform: uppercase;
        
        a {
            color: @color-main;
            
            &:hover {
                text-decoration: none;
            }
        }
    }

    .questions-list {
        padding-bottom: 15px;

        li {
            position: relative;
            padding-left: 15px;
            font-size: @font-xxs;
            line-height: 28px;

            &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: @color-orange;
            }
        }
    }

    .description {
        padding-bottom: 20px;
        font-size: @font-xxs;
        line-height: 28px;
    }

    form {
        .main-btn {
            padding: 12px 35px 13px;
        }
    }
}

// Social links

.block.social-links {
    padding: 21px 21px 32px;
    margin-bottom: 15px;
    border-top: 7px solid @color-orange;
    border-radius: 8px;
    overflow: hidden;
    background: #f8f8f8;

    .block-title {
        padding-bottom: 30px;
        font-size: @font-m;
        font-weight: @font-weight-bold;
        text-align: center;
    }

    .social-list {
        display: flex;
        justify-content: space-between;
        max-width: 320px;
        margin: 0 auto;
        padding: 0 15px;

        li {
            padding: 0 4px;
        }

        a {
            position: relative;
            display: block;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            transition: opacity .3s ease-in-out;

            &:hover {
                opacity: .7;
            }

            &::before {
                position: absolute;
                top: 50%;
                left: 51%;
                .icon;
                transform: translate(-50%, -50%);
                color: @color-white;
                font-size: 30px;
            }

            &.telegram {
                background: #44ACE7;

                &::before {
                    .icon-telegram;
                    left: 45%;
                    font-size: 25px;
                }
            }

            &.facebook {
                background: #1976D2;

                &::before {
                    .icon-facebook-1;
                }
            }

            &.instagram {
                background: linear-gradient(136.08deg, #405DE6 -15.57%, #5851DB 13.77%, #833AB4 40.81%, #E1306C 67.85%, #FD1D1D 94.89%), #C4C4C4;

                &::before {
                    .icon-instagram;
                    font-size: 26px;
                }
            }
        }
    }
}

// Crosssell block

.block.block-crosssell {
    border-top: 7px solid @color-orange;
    position: relative;
    border-radius: 8px;

    .block-title {
        padding: 18px 30px 10px;
        font-size: @font-xxl;
        font-weight: @font-weight-bold;

        .media-max(@screen-xxs, {
            padding-left: 20px;
        });
    }

    .block-content {
        margin-bottom: 15px;
    }

    .mini-products-list {
        position: static;
    }

    .slick-next, .slick-prev {
        top: 24px;
        right: 42px;
        transform: none;
        width: 28px;
        height: 28px;
        background: @color-black;

        .media-max(@screen-xxs, {
            right: 20px;
        });

        &:hover {
            background: @color-orange;

            &::before {
                color: @color-black;
            }
        }

        &::before {
            color: @color-white;
            font-size: 12px;
        }
    }

    .slick-prev {
        left: auto;
        right: 70px;

        .media-max(@screen-xxs, {
            right: 48px;
        });
    }

    .product {
        display: flex;
        padding: 27px 40px 8px 20px;
        border-bottom: 1px solid @color-border;

        .media-max(@screen-xxs, {
            padding-right: 20px;
        });

        .product-image {
            width: 70px;
        }

        .product-details {
            width: calc(~'100% - 70px');
            padding-left: 15px;

            .product-name {
                margin-bottom: 8px;

                a {
                    color: @color-black;
                    font-weight: @font-weight-bold;
                    text-transform: uppercase;
                    text-decoration: none;
                    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }

            .bottom-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;

                .price-box {
                    .price {
                        font-size: @font-xxl;

                        .media-max(@screen-xxs, {
                            font-size: @font-xl;
                        });
                    }

                    .old-price {
                        .price {
                            font-size: @font-m;

                            .media-max(@screen-xxs, {
                                font-size: @font-xxs;
                            });
                        }
                    }
                }

                .main-btn {
                    padding: 5px 15px;
                    border-radius: 10px;
                }

                .out-of-stock {
                    color: @color-red;
                    font-weight: @font-weight-bold;
                    font-size: @font-m;
                }
            }
        }
    }
}

// Load more

.meanbee-infinitescroll-button {
    .main-btn();
    display: block;
    max-width: 300px;
    margin: 0 auto 12px;
    padding: 8px 40px;
    border-radius: 43px;

    .media-min(@screen-m, {
        margin-bottom: 30px;
    });
}

.meanbee-infinitescroll-busy {
    height: 35px;
    padding: 0 20px !important;
    margin-bottom: 12px;

    .media-min(@screen-m, {
        margin-bottom: 30px;
    });
}

// Messages

.messages {
    padding: 16px 18px 10px;
    max-width: 1520px;
    margin: 0 auto !important;

    .media-min(@screen-xl, {
        padding: 0 0 20px;
    });

    ul {
        margin: 0 !important;
        padding: 0 !important;
    }

    li {
        display: inline-block;
        padding: 0 !important;
        margin: 0 !important;
        line-height: unset !important;

        &::before {
            display: none;
        }

        li {
            &::before {
                position: static;
                height: auto;
                border-radius: 0;
            }
        }

        &.success-msg {
            li {
                &::before {
                    background: @color-green;
                }
            }
        }

        &.error-msg {
            li {
                &::before {
                    background: @color-red;
                }
            }
        }

        li {
            display: flex;
            background: @color-border;
            border-radius: 8px;
            overflow: hidden;
            font-size: @font-m;
            font-weight: @font-weight-bold;
            text-align: left;

            &::before {
                content: '!';
                font-size: 28px;
                display: flex;
                width: 60px;
                align-items: center;
                justify-content: center;
                background: @color-red;
            }

            span {
                width: calc(~'100% - 60px');
                padding: 10px 17px 10px 22px;
            }
        }
    }
}

// Sticky

.sticky {
    position: sticky;
    top: 113px;
}

.nobr {
    white-space: nowrap !important;
}

// Display

.no-display {
    display: none !important;
}

.mobile-only {
    .media-min(@screen-m, {
        display: none !important;
    });
}

.tablet-only {
    .media-min(@screen-l, {
        display: none !important;
    })
}

.desktop-only {
    .media-max(@screen-m, {
        display: none !important;
    })
}

// Reviews

.dedicated-review-box {
    padding: 0 5px;

    .media-min(@screen-xl, {
        padding: 0;
    });

    .title-container {
        padding: 24px 20px 32px;
        margin-bottom: 8px;
        text-align: center;
        background: @color-light;
        border-radius: 8px;

        .media-min(@screen-m, {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 28px 30px 32px;
            margin-bottom: 16px;
            text-align: left;
        });

        h3 {
            font-weight: @font-weight-bold;
            font-size: @font-xxl;
            text-transform: uppercase;

            .media-min(@screen-m, {
                line-height: 44px;
            });
        }

        .main-btn {
            margin-top: 13px;
            width: 100%;
            max-width: 236px;
            padding: 13px 20px 12px;
            border-radius: 10px;

            .media-min(@screen-m, {
                margin: 0 0 0 15px;
            });
        }
    }

    .reviews-list {
        li {
            margin-bottom: 8px;
            background: @color-light;
            border-radius: 8px;

            .media-min(@screen-m, {
                margin-bottom: 16px;
            });

            .top-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 29px 20px 14px;

                .name {
                    font-size: @font-m;
                    font-weight: @font-weight-bold;
                }

                .date {
                    color: #a0a0a0;
                }
            }

            .content-wrap {
                padding: 0 20px 22px;

                .text-box {
                    &:not(:last-child) {
                        margin-bottom: 14px;
                    }

                    .label {
                        font-weight: @font-weight-bold;
                        margin-bottom: 12px;
                    }

                    .text {

                    }
                }
            }

            .rating-list {
                margin-bottom: 14px;

                .media-min(@screen-m, {
                    display: flex;
                });

                .rating-item {
                    display: flex;
                    margin-bottom: 8px;

                    .media-min(@screen-m, {
                        margin: 0 30px 0 0;
                    });

                    > span {
                        width: 35px;
                        margin-right: 14px;
                        font-size: @font-xxxs;
                    }

                    .rating-box {
                        position: relative;
                        width: 120px;
                        white-space: nowrap;
                        font-size: 0;

                        &::before {
                            .icon;
                            content: '\e912 \e912 \e912 \e912 \e912';
                            color: #797979;
                            font-size: 16px;
                            letter-spacing: 7px;
                        }

                        .rating {
                            position: absolute;
                            top: 0;
                            left: 0;
                            overflow: hidden;

                            &::before {
                                .icon;
                                content: '\e912 \e912 \e912 \e912 \e912';
                                color: @color-orange;
                                font-size: 16px;
                                letter-spacing: 7px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.review-nologged {
    margin: -25px 0 8px;
    padding: 0 20px 32px;
    background: @color-light;
    border-radius: 8px;
    text-align: center;

    .media-min(@screen-m, {
        padding: 0 30px 32px;
        text-align: left;
    });

    .media-min(@screen-xl, {
        margin-left: 0;
        margin-right: 0;
    });

    a {
        color: #FC5500;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

.add-review-popup-wrap {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-y: scroll;
    padding: 20px;
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;
    z-index: 99;
    overflow-y: auto;

    &.opened {
        opacity: 1;
        visibility: visible;
    }

    .box-wrap {
        position: absolute;
        top: 0;
        left: 0;
        display: table;
        width: 100%;
        height: 100%;
        padding: 15px;

        .box {
            display: table-cell;
            vertical-align: middle;

            .inner {
                position: relative;
                width: 100%;
                max-width: 670px;
                margin: 0 auto;
                background: @color-white;
                border-radius: 8px;
                border-top: 7px solid @color-orange;
                z-index: 2;
            }
        }
    }

    .top-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px 17px;
        border-bottom: 1px solid @color-border;

        .block-title {
            font-size: @font-xxl;
            font-weight: @font-weight-bold;
        }

        .close-review-popup-btn {
            font-size: 0;
            cursor: pointer;

            &::before {
                .icon;
                .icon-cross;
                font-size: 20px;
                color: #C4C4C4;
                transition: color .3s ease-in-out;
            }

            &:hover {
                &::before {
                    color: @color-main;
                }
            }
        }
    }

    .content-wrap {
        padding: 18px 20px 52px;

        h4 {
            margin-bottom: 18px;
            font-size: @font-m;
            font-weight: @font-weight-bold;
        }

        .data-table {
            width: auto;

            tr {
                text-align: left;

                &:not(:last-child) {
                    .rating-radio {
                        padding-bottom: 13px;
                    }
                }

                th {
                    display: table-cell;
                    padding: 0 22px 0 0;
                    vertical-align: middle;
                    font-size: @font-xs;
                    font-weight: @font-weight-regular;
                }

                td {
                    display: table-cell;
                    padding: 0;
                }

                .value {
                    &:not(:nth-child(6)) {
                        padding-right: 13px;

                        .media-max(@screen-xxs, {
                            padding-right: 7px;
                        })
                    }
                }

                .rating-radio {
                    display: inline-block;
                    color: #797979;
                    cursor: pointer;
                    transition: color .1s ease-in-out;

                    &::before {
                        .icon;
                        .icon-star;
                        font-size: 25px;
                    }

                    &.active {
                        color: @color-orange;
                    }
                }

                .selected-value {
                    display: none;
                }
            }
        }

        .form-list {
            margin: 30px 0 34px;

            li {
                margin-bottom: 10px;

                input {
                    .media-min(@screen-m, {
                        height: 55px;
                        font-size: @font-m;
                    });
                }
            }

            label {
                color: #979797;
                font-size: @font-xxxs;
                margin: 0;

                em {
                    display: none;
                }
            }
        }

        .actions-wrap {
            display: flex;
            justify-content: space-between;

            .media-max(@screen-m, {
                flex-direction: column-reverse;
            });

            .button {
                padding: 13px 20px 12px;
                width: calc(~'50% - 20px');

                .media-max(@screen-m, {
                    width: 100%;
                });
            }

            .close-review-popup-btn {
                position: relative;
                font-weight: @font-weight-bold;
                text-transform: uppercase;
                border-radius: 43px;
                transition: background .3s ease-in-out;
                overflow: hidden;

                > span {
                    position: relative;
                    z-index: 1;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    background: @color-orange;
                    transform: scaleX(0);
                    opacity: 0;
                    transform-origin: right;
                    transition: transform .3s cubic-bezier(0.330, 0.975, 0.245, 0.910), opacity .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);
                }

                &:hover {
                    &::after {
                        transform: scaleX(1);
                        opacity: 1;
                    }
                }
            }

            .main-btn {
                border-radius: 43px;

                .media-max(@screen-m, {
                    margin-bottom: 20px;
                });
            }
        }
    }

    .overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 1;
        cursor: pointer;
    }
}

.lab-tested{
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 75px;
    height: 45px;
    display: block;
    background: url('../images/lab-tested.svg') no-repeat center;
}