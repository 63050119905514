.clearfix () {
    content: '';
    display: table;
    clear: both;
}

.hidden () {
    position: absolute;
    top: -999em;
    left: -999em;
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    text-indent: -999em;
    overflow: hidden;
}

.link () {
    padding: 2px 3px;
    color: inherit;
    text-decoration: none;
    transition: background .3s ease-in-out, color .3s ease-in-out;

    &:hover {
        color: @color-black;
        background: @color-orange;
        transition: background .1s ease-in-out;
    }
}

.main-btn () {
    position: relative;
    display: inline-block;
    padding: 10px 20px;
    color: @color-black;
    background: @color-orange;
    text-align: center;
    font-family: @main-font;
    font-size: @font-main;
    text-decoration: none;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    overflow: hidden;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: color .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);

    > span {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: @color-black;
        transform: scaleX(0);
        opacity: 0;
        transform-origin: left;
        transition: transform .3s cubic-bezier(0.330, 0.975, 0.245, 0.910), opacity .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);
    }

    &:hover {
        color: @color-white;

        &::after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}

.secondary-btn () {
    position: relative;
    display: inline-block;
    padding: 10px 33px;
    color: @color-black;
    background: transparent;
    border: 2px solid @color-orange;
    border-radius: 10px;
    text-align: center;
    font-family: @main-font;
    font-size: @font-m;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
    overflow: hidden;
    cursor: pointer;
    transition: color .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);

    > span {
        position: relative;
        z-index: 1;
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: @color-orange;
        transform: scaleX(0);
        opacity: 0;
        transform-origin: left;
        transition: transform .3s cubic-bezier(0.330, 0.975, 0.245, 0.910), opacity .3s cubic-bezier(0.330, 0.975, 0.245, 0.910);
    }

    &:hover {
        &::after {
            transform: scaleX(1);
            opacity: 1;
        }
    }
}