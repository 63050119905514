// Default Variables

@slick-opacity-not-active: 0.25;

/* Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 45px;
    width: 45px;
    line-height: 0;
    font-size: 0;
    cursor: pointer;
    background: @color-orange;
    color: @color-black;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    transition: opacity .3s ease-in-out, background .3s ease-in-out;
    z-index: 2;

    &:hover {
        outline: none;
        background: @color-black;

        &::before {
            color: @color-white;
        }
    }

    &.slick-disabled {
        opacity: @slick-opacity-not-active;
        pointer-events: none;
    }
}

.slick-prev {
    left: 0;

    [dir="rtl"] & {
        left: auto;
        right: 0;
    }

    &:before {
        .icon;
        .icon-arrow-left;
        font-size: 20px;
        transition: color .3s ease-in-out;
    }
}

.slick-next {
    right: 0;

    [dir="rtl"] & {
        left: 0;
        right: auto;
    }

    &:before {
        .icon;
        .icon-arrow-right;
        font-size: 20px;
        transition: color .3s ease-in-out;
    }
}

/* Dots */

.slick-dotted .slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    display: block;
    width: 100%;
    padding: 0;
    margin: 10px 0 !important;
    list-style: none;
    text-align: center;
    font-size: 0;

    li {
        display: inline-block;
        margin: 0 6px;
        padding: 0 !important;

        &::before {
            display: none;
        }

        button {
            display: block;
            height: 17px;
            width: 17px;
            background: #DCDCDC;
            border: 0;
            border-radius: 50%;
            line-height: 0;
            font-size: 0;
            outline: none;
            cursor: pointer;
            transition: opacity .3s ease-in-out, background .3s ease-in-out;

            .media-min(@screen-m {
                width: 13px;
                height: 13px;
            });

            &:hover, &:focus {
                outline: none;
                opacity: .75;
            }
        }

        &.slick-active button {
            background: @color-orange;
        }
    }
}

/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    img {
        display: block;
    }

    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}
